import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { IUserGroup, IUserGroupApiResponse } from '../user-groups';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IGrowthPlan, IGrowthPlanResponse, ISearchPayload } from '../../layouts/admin-goal-management-layout';
import { IAccomplishment, ICourse, IDevelopmentPlanRequest, IFormData, IMasterCourseDataResponse, IMasterCourseData, ISelectedAccomplishmentResponse, ISkillDetailsResponse, ISkillResponseData, ICertificationMasterData, ICertification, IUserResponse, IUserReportees, IUserGoalData } from '.';
import { DataService } from 'src/app/services/data.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DIALOG_TYPES } from 'src/app/constants';
import { MatTableDataSource } from '@angular/material/table';
import { IGoal } from '../goals';
import { EMPTY, Observable, Subject } from 'rxjs';
import { GoalService } from 'src/app/services/goal.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ToastService } from 'src/app/modules/common-components/toast/toast.service';
import { IUserInfo } from 'src/app/modules/team-goals/team-goals-layout';
import { IUser } from 'src/app/modules/team-skill-approvals/team-skill-approvals-v2/team-skill-approvals.model';
import { CreateUserGroupComponent } from '../../../notification-triggers/create-user-group/create-user-group.component';

export interface IGoalEntityTypeRole {
  title: string;
  mandatorySkills: ISkill[];
  optionalSkills: ISkill[];
  totalCurrentRating: number;
  totalExpectedRating: number;
}

export interface ISkill {
  skillName: string;
  currentRating: number;
  expectedRating: number;
}
export interface IAccomplishmentMappedResponse {
  title: string;
  id: string;
}

export interface IPaginationVirtual {
  limit?: number;
  offset?: number;
}

export enum AccomplishmentType {
  Skill = 'skill',
  Custom = 'custom',
  Course = 'course',
  Certification = 'certification',
  Specialization = 'specialization',
  Role = 'role',
  Designation = 'designation',
}

export enum DevelopmentPlanStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  NoPlans = 'No development plans',
}

export enum AccomplishmentId {
  Skill = '1',
  Custom = '2',
  Course = '3',
  Certification = '4',
  Specialization = '5',
  Role = '6',
}

@Component({
  selector: 'app-add-goal',
  templateUrl: './add-goal.component.html',
  styleUrls: ['./add-goal.component.scss']
})
export class AddGoalComponent implements OnInit, OnDestroy {

  @ViewChild('skillSelect') skillSelect!: NgSelectComponent;
  @ViewChild('courseSelect') courseSelect!: NgSelectComponent;

  @Output() onEvent = new EventEmitter();
  @Output() refresh = new EventEmitter();
  @Output() loadMore = new EventEmitter<void>();

  public title: string = 'Add New Goal';

  public editGoalObj: IGoal;
  public isMyGoals: boolean = false;
  public isTeamGoals: boolean = false;
  public userIds: string[];
  public userInfo: IUserInfo[];

  public userGroupData: IUserGroupApiResponse;
  public growthPlanData: IGrowthPlanResponse;

  public accomplishments: IAccomplishment[] = [
    { id: '1', type: AccomplishmentType.Skill,          label: 'Develop a skill',           icon: '', selected: false },
    { id: '3', type: AccomplishmentType.Course,         label: 'Complete a course',         icon: '', selected: false },
    { id: '4', type: AccomplishmentType.Certification,  label: 'Complete a certification',  icon: '', selected: false },
    { id: '5', type: AccomplishmentType.Specialization, label: 'Complete a specialization', icon: '', selected: false },
    { id: '6', type: AccomplishmentType.Role,           label: 'Develop for a role',        icon: '', selected: false },
    { id: '2', type: AccomplishmentType.Custom,         label: 'Custom Goal',               icon: '', selected: false },
  ];

  public goalTypeIconsMap: { [key: string]: string } = this.goalService.goalTypeIconsMap;

  public selectedAccomplishment: any = null;
  public dataForSelectedAccomplisment: IAccomplishmentMappedResponse[] = [];
  public selectedAccomplismentApiResponse: ISkillResponseData | ISelectedAccomplishmentResponse;
  public subCategoryAccomplishment = this.subCategoryAccomplishmentInit();
  public dynamicFormControls: { [key: string]: { showSelect: boolean, selectedItem: any } } = {};

  public planForm: FormGroup;
  public developmentPlans: IGrowthPlan[] = [];
  public ratings = [
    { id: 0, value: 1 },
    { id: 1, value: 2 },
    { id: 2, value: 3 },
    { id: 3, value: 4 }
  ];
  public improvementMethods = ['Self Learning', 'Training', 'Certification', 'Project Experience'];
  public measurementOptions = [
    { label: 'Complete/Incomplete', value: 'complete_incomplete' },
    { label: 'Numeric', value: 'numeric' },
    {
      label: 'Currency', value: 'currency', subOptions: [
        { label: 'USD', value: 'usd', icon: 'attach_money' },
        { label: 'EUR', value: 'eur', icon: 'euro_symbol' },
        { label: 'INR', value: 'inr', icon: 'currency_rupee' }
      ]
    },
    { label: '% Percentage', value: 'percentage' }
  ];
  public lockGoalsSetting = false;
  public activePlanEndDate: Date;
  public allSelected: boolean = false;
  public isLoading = false; // To prevent multiple requests

  public displayedColumns: string[] = ['skill', 'current', 'expected'];

  public dataSourceMandatory: MatTableDataSource<any>;
  public dataSourceOptional: MatTableDataSource<any>;
  public dataSourceCondtionalMandatory: MatTableDataSource<any>;
  public showGoalOwnerDropdown: boolean = false
  public usersList: IUserReportees[] = [];
  public clickedRows: Set<any> = new Set();
  public minDate: Date = new Date();
  public loadingAccomplishments: boolean = false;
  public dialogLabel: string;
  public currentUser :IUser | null;
  // TODO: remove once role, specialization data is coming correctly [what should be the logic to calculate?]
  public dynamicBadgeText: string = 'Any 1 skill';

  // TODO: remove once BE supports currency changes
  private readonly currencyOptions = ['USD', 'EUR', 'INR'];

  private readonly CATEGORY_ASSIGNED = 'ASSIGNED';
  private readonly CATEGORY_RECOMMEND = 'RECOMMENDED';
  private readonly MANDATORY = 'mandatory';
  private readonly OPTIONAL = 'optional';
  private ENDPOINT_GOAL_DATA = API_ENDPOINT.GET_GOAL_DATA_LIST_IDP;
  private apiMethod: (endpoint: string, payload: any) => Observable<any>;
  private unsubscribe$ = new Subject();
  private dataForSelectedAccomplisment$ = new Subject();
  private searchText$ = new Subject<string>();
  checkboxClick = false;
  aliasDictionary: any;
  infoTips: Record<AccomplishmentId, string>;
  private readonly currentRatingReadonlyValue: string = '(Differs across users)';
  private isGrowthPlanMandatory: boolean = true;
  private searchText: string;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public util: UtilitiesService,
    private ds: DataService,
    public weds: WaitErrorDialogsService,
    private goalService: GoalService,
    private toast: ToastService,
  ) {}

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {

    this.ENDPOINT_GOAL_DATA = this.isNotAdmin ? API_ENDPOINT.GET_GOAL_DATA_LIST_MY_GOALS : this.ENDPOINT_GOAL_DATA;
    this.apiMethod =  this.isNotAdmin ? this.ds.careerPrismDataPostApi.bind(this.ds) : this.ds.postApi.bind(this.ds);

    this.initialConfigForAccomplishment();
    this.createForm();
    this.HandleEditGoalScenario();
    this.subscribeToDevelopmentPlanChanges();
    this.subscribeToSkillsSelectTextChanges();
    this.watchForMeasurementChanges();

    if(this.growthPlanData?.data?.length) {
      this.developmentPlans = [...this.growthPlanData.data];
      this.checkDevelopmentPlans();
      this.checkMandatoryDevelopmentPlan();
    }

    if(this.isTeamGoals && !this.editGoalObj) {
      this.checkForCurrentUserOrGetGoalOwners();
    }

    this.aliasDictionary = this.ds.dictionary;
    this.infoTips = {
      [AccomplishmentId.Skill]: `The goal is marked as complete when the skill is endorsed on the My ${this.aliasDictionary.skillsAndCompetencies} page`,
      [AccomplishmentId.Custom]: `The goal needs to be marked as complete using the check-in button`,
      [AccomplishmentId.Course]: `The goal is marked as complete when the course is completed to 100%`,
      [AccomplishmentId.Certification]: `The goal is marked as complete when the certification is added to My Certifications page`,
      [AccomplishmentId.Specialization]: `The goal is marked as complete when you complete the specialization in My ${this.aliasDictionary.specializations} page`,
      [AccomplishmentId.Role]: `The goal is marked as complete when your role changes to the target role`
    };
    this.dialogLabel = this.isTeamGoals ? 'What do you want them to accomplish?' : 'What do you want to accomplish?';
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public watchForMeasurementChanges() {
    this.planForm.get('measurement')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: string) => {
      const controlsList = ['targetRating', 'currentRating'];
      if (value === 'complete_incomplete') {
        this.clearAndRestValidators(controlsList);
      } else {
        controlsList.forEach(control => {
          this.planForm.get(control)?.setValidators(Validators.required);
          this.markControlAsInValid(control);
        });
      }
    });
  }

  public onSearch(searchText: any) {
    this.searchText$.next(searchText.term);
  }

  public checkDevelopmentPlans(): void {
    const activePlans = this.developmentPlans.filter(plan => plan.status !== DevelopmentPlanStatus.Active);
    if (activePlans.length === 0) {
      this.planForm.get('developmentPlan')?.disable();
      this.planForm.get('developmentPlan')?.setValue(null);
    } else if (this.lockGoalsSetting) {
      this.developmentPlans = this.developmentPlans.filter(plan => plan.status === DevelopmentPlanStatus.Active);
    }
  }

  public toggleSelect(accomplishmentId: string): void {
    this.resetToIntialState();
    this.dynamicFormControls[accomplishmentId].showSelect = true;
    setTimeout(() => {
      if (accomplishmentId === AccomplishmentId.Skill && this.skillSelect) {
        this.skillSelect.open();
      } else if (accomplishmentId === AccomplishmentId.Course && this.courseSelect) {
        this.courseSelect.open();
      }
    });
  }

  public toggleAllUsers(event: any): void {
    this.allSelected = !this.allSelected;
    this.userGroupData.data.userGroupDetails?.forEach(group => group.selected = this.allSelected);
    this.updateFormValue();
    if (event.target.tagName === 'INPUT') {
      this.checkboxClick = true;
    }
  }

  public syncCheckboxes(event: Event): void {
    if (this.checkboxClick) {
      this.checkboxClick = false;
      return;
    }

    const selectedUserGroups: any[] = this.planForm.get('userGroups').value;
    this.allSelected = false;

    this.userGroupData.data.userGroupDetails.forEach(group => {
      group.selected = selectedUserGroups.includes(group);
    });
  }

  public onRemoveClick(item: IUserGroup) {
    this.removeItemFromControl('userGroups', item, 'userGroupId');
  }

  public onItemSelect(accomplishmentId: string, item: { id: string, title: string }): void {
    this.resetToIntialState();

    this.dynamicFormControls[accomplishmentId].selectedItem = item;
    this.dynamicFormControls[accomplishmentId].showSelect = false;

    switch(accomplishmentId) {
      case AccomplishmentId.Skill:
        this.getEnrichmentData(item.id, AccomplishmentType.Skill);
      break;
      case AccomplishmentId.Course:
        this.getEnrichmentData(item.id, AccomplishmentType.Course);
      break;
      case AccomplishmentId.Certification:
        this.getEnrichmentData(item.id, AccomplishmentType.Certification);
      break;
      case AccomplishmentId.Specialization:
        this.getEnrichmentData(item.id, AccomplishmentType.Specialization);
      break;
      case AccomplishmentId.Role:
        this.getEnrichmentData(item.id, AccomplishmentType.Role);
      break;
    }
  }

  public deselect(accomplishmentId: string): void {
    this.dynamicFormControls[accomplishmentId].selectedItem = null;
    this.dynamicFormControls[accomplishmentId].showSelect = false;

    if (accomplishmentId !== AccomplishmentId.Custom) {
      this.planForm.get('enrichmentList').reset();
    }
  }

  public onCreateUpdateUserGroup(event?: any, userData?: any): any {
    let dialogRef = this.dialog.open(CreateUserGroupComponent, {
      width: window.innerWidth < 900 ? 0.9 * window.innerHeight + 'px' : '600px', height: window.innerWidth < 900 ? 0.9 * window.innerHeight + 'px' : '600px',
      panelClass: 'notification-dialog',
      disableClose: true
    });
    dialogRef.componentInstance.notificationData = userData || {};
    dialogRef.componentInstance.config = userData ? { 'enableEditUserGroup': true } : {};
    const action = userData ? 'updated' : 'created';
    const msg = `User group ${action}`;
    const desc = `You have successfully ${action} a user group.`;
    dialogRef.componentInstance.onEvent.subscribe((res) => {
      if (res.type == 'REFRESH') {
        dialogRef.close()
        this.toast.showToast({ type: 'success', msg: msg, desc: desc });
        this.getUserGroups();
        dialogRef.close();
      }
    });
  }


  public onScrollToEnd() {
    this.loadUserGroups();
  }

  public onClose() {
    this.onEvent.emit();
  }

  public addNewGoal() {
    if (this.planForm.invalid) {
      this.planForm.markAllAsTouched();
      return;
    }
    const selectedAccomplishment: any = this.dynamicFormControls[this.planForm.value?.selectedAccomplishment]?.selectedItem ?? '';
    const requestObj = this.mapToUpdateGoalRequestFormat(this.planForm.value, selectedAccomplishment);

    if(this.editGoalObj) {
      this.updateGoal(requestObj);
    } else {
      this.createNewGoal(requestObj);
    }
  }

  public dismiss() {
    this.onEvent.emit();
  }

  public onDataLoaded() {
    this.isLoading = false;
  }

 public onAccomplishmentChange(selectedItem: any): void {
    this.resetToIntialState();
    this.dataForSelectedAccomplisment = [];
    this.selectedAccomplismentApiResponse = null;

    this.clearAndRestValidators(['targetRating','improvementMethod']);
    this.setOrClearValidatorForCustom(selectedItem.type);
    this.setValidatorsForSkillType(selectedItem.type);
    this.fetchSelectedAccomplishmentsList(selectedItem.type);
  }

  public showUserSelect() {
    this.showGoalOwnerDropdown = true;
  }

  public onRemoveUserClick(item: IUserReportees) {
    this.removeItemFromControl('goalOwner', item, 'userId');
  }

  public onScrollToEndAccomplishments(accomplishmentId: string) {
    const type: any = this.getAccomplishmentType(accomplishmentId).toLowerCase();
    const paginationData: IPaginationVirtual = this.generatePaginationData(type);

    if(paginationData?.offset) {
      this.fetchSelectedAccomplishmentsList(type, paginationData);
    }
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private resetToIntialState() {
    this.searchText = null;
    this.initialConfigForAccomplishment();
    this.subCategoryAccomplishment = this.subCategoryAccomplishmentInit();
    this.resetSepecializationDataSources();
  }

  private resetSepecializationDataSources() {
    this.dataSourceMandatory = null;
    this.dataSourceOptional = null;
    this.dataSourceCondtionalMandatory = null;
  }

  private getUserGroups(payload: ISearchPayload = {}) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const clientId = this.ds.currentAdminClientId;
    const defaultPayload = { filters: [], fetchGoals: true, page: 1, limit: 10, getTotalCount: true };
    const finalPayload = { ...defaultPayload, ...payload };
    this.ds.careerPrismDataPostApi(API_ENDPOINT.SEARCH_USER_GROUPS(clientId), finalPayload).pipe(take(1)).subscribe((res: IUserGroupApiResponse) => {
      this.userGroupData.data.userGroupDetails = res.data.userGroupDetails;
      this.weds.closeDialog(dialogRef);
    });
  }

  private fetchSelectedAccomplishmentsList(type: AccomplishmentType, pagination: any = {}) {
    const currentRating: AbstractControl<any, any> = this.planForm.get('currentRating');
    currentRating?.setValue(this.currentRatingReadonlyValue);
    currentRating?.disable();

    switch (type) {
      case AccomplishmentType.Course:
        this.getCourses(pagination, this.searchText);
        break;
      case AccomplishmentType.Skill:
        this.getSkills(pagination, this.searchText);
        break;
      case AccomplishmentType.Specialization:
        this.getSpecializations(pagination, this.searchText);
        break;
      case AccomplishmentType.Certification:
        this.getCertifications(pagination, this.searchText);
        break;
      case AccomplishmentType.Role:
        this.getDesignation(pagination, this.searchText);
        break;
      default:
        currentRating?.setValue('');
        currentRating?.enable();
    }
  }

  private subscribeToSkillsSelectTextChanges() {
    this.searchText$
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        filter(searchText => searchText !== null),
        switchMap(searchText => {
          this.loadingAccomplishments = true;
          this.searchText = searchText;
          this.handleTypeAhead(searchText);
          return EMPTY;
        })
      )
      .subscribe(data => {
      });
  }

  private handleTypeAhead(searchText: string) {
    const selectedAccomplishmentId = this.planForm?.value?.selectedAccomplishment;
    const selectedAccomplishmentType = this.accomplishments?.find(accomplishment => accomplishment?.id === selectedAccomplishmentId)?.type;
    const defaultPagination = { limit: 10, offset: 0 };

    switch (selectedAccomplishmentType) {
      case AccomplishmentType.Skill:
        this.getSkills(defaultPagination, searchText);
        break;
      case AccomplishmentType.Course:
        this.getCourses(defaultPagination, searchText);
        break;
      case AccomplishmentType.Specialization:
        this.getSpecializations(defaultPagination, searchText);
        break;
      case AccomplishmentType.Certification:
        this.getCertifications(defaultPagination, searchText);
        break;
      case AccomplishmentType.Role:
        this.getDesignation(defaultPagination, searchText);
        break;
    }
  }

  private getGoalOwners() {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const requestPayload = this.buildRequestPayloadAdmin('DIRECT_REPORTING_EMPLOYEES' as any);
    requestPayload.forAutoComplete = null;
    requestPayload.filters = [];
    requestPayload.sort = {
            field: 'name',
            order: 'ASC'
    };
    requestPayload.offset = 0;
    this.apiMethod(API_ENDPOINT.GET_GOAL_OWNERS, requestPayload).pipe(take(1)).subscribe((res: IUserResponse) => {
      this.weds.closeDialog(dialogRef);
      this.usersList = res.data;
    });
  }

  private checkForCurrentUserOrGetGoalOwners() {
    if(this.currentUser) {
      this.planForm.patchValue({ goalOwner: [this.currentUser] });
    }
    else {
      this.getGoalOwners();
    }
  }

  private getEnrichmentData(id: string, type: AccomplishmentType) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const client = this.ds.currentAdminClientId;
    const requestPayload = {
      clientId: client,
      type: type,
      id: id,
    };

    const endpoint = this.isNotAdmin ? API_ENDPOINT.GET_EXTRA_DETAILS_MYGOAL : API_ENDPOINT.GET_EXTRA_DETAILS_IDP;

    this.ds.postApi(endpoint, requestPayload).pipe(take(1)).subscribe((res: ISkillDetailsResponse | IMasterCourseDataResponse | ICertificationMasterData | any) => {
        this.weds.closeDialog(dialogRef);
        this.subCategoryAccomplishment[type] = { ...res?.data?.masterGoalData ?? {} };
        if(res?.data) {
          this.handleEnrichmentBasedOnType(type, res);
        }

        if(this.editGoalObj) {
          const updatedType = type !== this.editGoalObj.type?.toLowerCase() ? type : null
          this.patchEditValue(this.editGoalObj, updatedType);
        }

        if(res.error) {
          this.resetToIntialState();
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: res.error ? res.error.code : 99, dontCloseAllDialogs: true });
        }
    });
  }

  private handleEnrichmentBasedOnType(type: AccomplishmentType, res: any) {
    const userGoalData = (res as ISkillDetailsResponse).data.userGoalData;

    switch (type) {
      case AccomplishmentType.Course:
        const courseProgress = res.data.userGoalData?.data?.progress;
        if(courseProgress >=0 ) {
          this.planForm.get('currentRating').patchValue(courseProgress);
        }
        this.subCategoryAccomplishment.course = this.mapMasterGoalDataToCourse(this.subCategoryAccomplishment[type]);
        break;
      case AccomplishmentType.Skill:
        const currentRating = this.getCurrentRating(userGoalData);
        this.planForm.get('currentRating').patchValue(currentRating);
        break;
      case AccomplishmentType.Certification:
        const isCertificationIdPresent = res.data?.userGoalData?.data?.certificationId;
        if(isCertificationIdPresent) {
          this.planForm.get('currentRating').patchValue(1);
        }
        this.subCategoryAccomplishment.certification = this.mapMasterGoalDataToCertification(this.subCategoryAccomplishment[type]);
        break;
      case AccomplishmentType.Specialization:
        if (userGoalData?.data?.status?.toLowerCase() === 'completed') {
          this.planForm.get('currentRating').patchValue(1);
        }
        this.handleDetailResponseForRoleAndSpecialization(type);
        break;
      case AccomplishmentType.Role:
        this.handleDetailResponseForRoleAndSpecialization(type);
        break;
    }
  }

  private patchEnrichment(selectedAccomplishmentId: string, updatedType?: AccomplishmentType) {
    const accomplishmentType = updatedType ?? this.editGoalObj.type.toLowerCase();
    if(accomplishmentType !== 'custom') {
      const selectedItem = {
        id: this.subCategoryAccomplishment[accomplishmentType].pk || this.subCategoryAccomplishment[accomplishmentType]?.specializationId || this.subCategoryAccomplishment[accomplishmentType]?.skillItemId,
        title: this.subCategoryAccomplishment[accomplishmentType]?.title || this.subCategoryAccomplishment[accomplishmentType]?.name
      };
      this.dynamicFormControls[selectedAccomplishmentId].showSelect = true;
      this.dynamicFormControls[selectedAccomplishmentId].selectedItem = selectedItem;
      this.planForm.patchValue({ enrichmentList: selectedItem });
    }
  }

  private getCurrentRating(userGoalData: IUserGoalData) {
    return Math.max(
      userGoalData?.data?.projectRating || 0,
      userGoalData?.data?.incomingRating || 0,
      userGoalData?.data?.trainingRating || 0,
      userGoalData?.data?.selfAcquiredRating || 0,
      userGoalData?.data?.overallRating || 0
    );
  }

  private handleDetailResponseForRoleAndSpecialization(type: AccomplishmentType) {
    const mappedData = this.tranformDataForTable(type);
    this.dataSourceMandatory = new MatTableDataSource(mappedData.mandatory);
    this.dataSourceOptional = new MatTableDataSource(mappedData.optional);
    this.dataSourceCondtionalMandatory = new MatTableDataSource(mappedData.mandatoryoptional);
  }

  private tranformDataForTable(type: AccomplishmentType) {
    const mappedData = {
      title: this.subCategoryAccomplishment[type]?.name,
      specializationId: this.subCategoryAccomplishment[type]?.specializationId,
      mandatory: [],
      optional: [],
      mandatoryoptional: [],
    };

    this.subCategoryAccomplishment[type]?.skillItems?.forEach(item => {
      const mappedItem = {
        skillName: item.skillName,
        expectedRating: item.exitRating,
        currentRating: 'NA'
      };

      if (item.category?.toLowerCase() === this.MANDATORY) {
        mappedData.mandatory.push(mappedItem);
      } else if (item.category?.toLowerCase() === this.OPTIONAL) {
        mappedData.optional.push(mappedItem);
      } else {
        mappedData.mandatoryoptional.push(mappedItem);
      }
    });
    return mappedData;
  }

  private getSkills(pagination: IPaginationVirtual = {}, searchText: string = '') {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const clientId = this.ds.currentAdminClientId;
    const requestPayload = this.isNotAdmin ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Skill) : this.buildRequestPayloadAdmin(AccomplishmentType.Skill);

    const queryParams = new HttpParams()
      .set('limit', (pagination.limit || 10).toString())
      .set('page', (pagination.offset || 0).toString());

    this.loadingAccomplishments = true;

    const baseURL = this.isNotAdmin ? `${API_ENDPOINT.SEARCH_SKILLS_MY_GOAL(clientId)}${searchText}` : `${API_ENDPOINT.SEARCH_SKILLS(clientId)}${searchText}`;
    this.ds.postApi(`${baseURL}&${queryParams.toString()}`, requestPayload).pipe(take(1)).subscribe((res: ISkillResponseData) => {
      this.weds.closeDialog(dialogRef);
      this.handleSkillResponse(res, searchText, pagination);
    })
  }

  private handleSkillResponse(res: ISkillResponseData, searchText: string, pagination: IPaginationVirtual) {
    this.loadingAccomplishments = false;
    this.selectedAccomplismentApiResponse = { ...res };

    const newAccomplishments = res.data?.map(item => ({ title: item.title, id: item.pk })) ?? [];
    this.dataForSelectedAccomplisment = pagination?.offset ? [...this.dataForSelectedAccomplisment, ...newAccomplishments] : newAccomplishments;

    this.dataForSelectedAccomplisment$.next(true);
  }

  private get isNotAdmin() {
    return this.isMyGoals || this.isTeamGoals;
  }

  private getCourses(pagination: IPaginationVirtual = {}, search?: string) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    let requestPayload = this.isNotAdmin ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Course) : this.buildRequestPayloadAdmin(AccomplishmentType.Course);
    requestPayload = { ...requestPayload, ...pagination, search };

    if(!this.isMyGoals && !this.isTeamGoals) {
      requestPayload.filterOnRole = 1;
    }
    this.loadingAccomplishments = true;

    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.loadingAccomplishments = false;
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = [
          ...(pagination?.offset ? this.dataForSelectedAccomplisment : []),
          ...(res.data.searchData.map(course => ({ title: course.coursename, id: course.id })) ?? [])
        ];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getSpecializations(pagination: IPaginationVirtual = {}, search?: string) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    let requestPayload = this.isNotAdmin ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Specialization) : this.buildRequestPayloadAdmin(AccomplishmentType.Specialization);
    requestPayload = { ...requestPayload, ...pagination, search };
    this.loadingAccomplishments = true;

    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.loadingAccomplishments = false;
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = [
          ...(pagination?.offset ? this.dataForSelectedAccomplisment : []),
          ...(res.data.searchData?.map(item => ({ title: item.specializationname, id: item.id })) ?? [])
        ];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getCertifications(pagination: IPaginationVirtual = {}, search?: string) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    let requestPayload = this.isNotAdmin ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Certification) : this.buildRequestPayloadAdmin(AccomplishmentType.Certification);
    requestPayload = { ...requestPayload, ...pagination, search };
    this.loadingAccomplishments = true;

    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.loadingAccomplishments = false;
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = [
          ...(pagination?.offset ? this.dataForSelectedAccomplisment : []),
          ...(res.data.searchData?.map(item => ({ title: item.certificationname, id: item.id })) ?? [])
        ];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private getDesignation(pagination: IPaginationVirtual = {}, search?: string) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    let requestPayload = this.isNotAdmin ? this.getPayloadForGoalListBasedOnType(AccomplishmentType.Designation) : this.buildRequestPayloadAdmin(AccomplishmentType.Designation);
    requestPayload = { ...requestPayload, ...pagination, search };
    this.loadingAccomplishments = true;

    this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((res: ISelectedAccomplishmentResponse) => {
        this.weds.closeDialog(dialogRef);
        this.loadingAccomplishments = false;
        this.selectedAccomplismentApiResponse = {...res};
        this.dataForSelectedAccomplisment = [
          ...(pagination?.offset ? this.dataForSelectedAccomplisment : []),
          ...(res.data.searchData?.map(item => ({ title: item.designationname, id: item.id })) ?? [])
        ];
        this.dataForSelectedAccomplisment$.next(true);
    });
  }

  private initialConfigForAccomplishment() {
    this.accomplishments.forEach(accomplishment => {
      this.dynamicFormControls[accomplishment.id] = { showSelect: false, selectedItem: null };
    });
  }

  private createForm() {
    this.planForm = this.fb.group({
      selectedAccomplishment: [null, Validators.required],
      enrichmentList: [null, Validators.required],
      developmentPlan: [{ value: '', disabled: false }],
      dueDate: [''],
      goalName: ['', [Validators.maxLength(500)]],
      goalDescription: ['', [Validators.maxLength(500)]],
      currentRating: [{ value: this.currentRatingReadonlyValue, disabled: true }],
      targetRating: ['', Validators.required],
      measurement: ['', Validators.required],
      improvementMethod: [[], Validators.required],
      managerApproval: [false],
      measurement_currentRating: [{value: 'Incomplete', disabled: true}],
      measurement_targetRating: [{value: 'Complete', disabled: true}],
    });

    if (!this.isMyGoals && !this.isTeamGoals) {
      this.planForm.addControl('category', this.fb.control(1, Validators.required));
      this.planForm.addControl('userGroups', this.fb.control(null, Validators.required));
    }
    if (this.isTeamGoals) {
      this.planForm.addControl('goalOwner', this.fb.control(''));
    }
  }

  private setValidatorsForSkillType(type: AccomplishmentType) {
    this.planForm.get('targetRating')?.patchValue('');
    this.planForm.get('currentRating')?.patchValue('');

    if(type === AccomplishmentType.Skill) {
      this.setSkillRatingValidators();
    }
  }

  private setSkillRatingValidators() {
    this.markControlAsInValid('targetRating');
    this.markControlAsInValid('improvementMethod');
  }

  private setOrClearValidatorForCustom(type: AccomplishmentType) {
    const controlsList = ['measurement', 'goalName'];
    if(type === AccomplishmentType.Custom) {
        this.planForm.get('enrichmentList')?.clearValidators();
        this.markControlAsValid('enrichmentList');

        controlsList.forEach(control => {
          this.planForm.get(control)?.setValidators(Validators.required);
          this.markControlAsInValid(control);
        });

      } else {
        this.planForm.get('enrichmentList')?.setValidators(Validators.required);
        this.markControlAsInValid('enrichmentList');

        controlsList.forEach(control => {
          this.planForm.get(control)?.clearValidators();
          this.markControlAsValid(control);
        });
    }
  }

  private clearAndRestValidators(keyNames: string[]) {
    keyNames.forEach(key => {
      this.planForm.get(key)?.clearValidators();
      this.markControlAsValid(key);
    });
  }

  private markControlAsValid(control: string) {
    this.planForm.get(control)?.setErrors(null);
    this.planForm.get(control)?.updateValueAndValidity();
  }

  private markControlAsInValid(control: string) {
    this.planForm.get(control)?.setErrors({ required: true });
    this.planForm.get(control)?.setValidators(Validators.required);
  }

  private checkMandatoryDevelopmentPlan(): void {
    if (this.isGrowthPlanMandatory) {
      this.planForm.get('developmentPlan')?.setValidators(Validators.required);
    } else {
      this.planForm.get('developmentPlan')?.clearValidators();
    }
    this.planForm.get('developmentPlan')?.updateValueAndValidity();
   }

  private patchEditValue(editGoalObj: IGoal, updatedType?: AccomplishmentType) {
    if (editGoalObj) {
      const accomplishmentType = updatedType ?? this.editGoalObj.type.toLowerCase();
      const selectedAccomplishmentId = this.accomplishments.find(x => x.type === accomplishmentType).id;
      const userGroups = [];
      if(this.userGroupData?.data) {
        this.userGroupData.data.userGroupDetails.forEach(group => {
          if (editGoalObj.userGroups.includes(group.id)) {
            userGroups.push(group);
          }
        });
      }

      this.patchEnrichment(selectedAccomplishmentId, updatedType);

      // TODO: remove currency check once BE supports currency changes
      const measurement = editGoalObj?.measurement?.includes('CURRENCY_') ? editGoalObj?.measurement.split('_')[1] : editGoalObj?.measurement;

      const improvementMethod = editGoalObj?.improvementMethod?.map((method: string) => method?.toLowerCase()?.split('_')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ')) || [];

      this.planForm.patchValue({
        selectedAccomplishment: selectedAccomplishmentId,
        goalName: editGoalObj.title,
        developmentPlan: editGoalObj?.developmentPlanId,
        goalDescription: editGoalObj?.description,
        measurement: editGoalObj?.measurement || measurement,
        improvementMethod: improvementMethod || [],
        targetRating: editGoalObj?.requiredValue,
        userGroups: userGroups ?? null,
        category: editGoalObj.category === this.CATEGORY_ASSIGNED ? 1 : 2,
        dueDate: new Date(editGoalObj.goalDeadline),
        visibility: null,
        managerApproval: !!this.editGoalObj.developmentPlanId || (this.editGoalObj.approvalStatus !== 'NOT_REQUIRED') || this.editGoalObj.goalSource === 'UI_ADMIN'
      });

      setTimeout(() => {
        this.planForm.controls.currentRating.setValue(editGoalObj?.currentValue || this.planForm.value.currentRating);
      }, 0);

      if(!this.isMyGoals && !this.isTeamGoals) {
        this.planForm.controls.userGroups.disable();
      }

      if(this.isTeamGoals) {
        const found = this.userInfo?.filter(user => user.userId === this.editGoalObj.userId);
        this.planForm.patchValue({goalOwner: found || []});
      }
    }
  }

  private loadUserGroups() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loadMore.emit();
    }
  }

  private updateFormValue(): void {
    const selectedGroups = this.userGroupData.data.userGroupDetails?.filter(group => group.selected);
    this.planForm.get('userGroups').setValue(selectedGroups);
  }

  private removeItemFromControl(controlName: string, item: any, identifier: string): void {
    const control = this.planForm.get(controlName);
    if (control) {
      const items = control.value || [];
      const updatedItems = items.filter((i: any) => i[identifier] !== item[identifier]);
      control.setValue(updatedItems);
      if (controlName === 'userGroups') {
        this.syncCheckboxes(null);
      }
    }
  }

  private mapToUpdateGoalRequestFormat(formValue: IFormData, { title, id }: { title: string; id: string }): IDevelopmentPlanRequest {
    const type: any = this.getAccomplishmentType(formValue.selectedAccomplishment);
    const currentRating = this.planForm.controls['currentRating'].value;
    const res: IDevelopmentPlanRequest = {
      data: {
        goalId: this.editGoalObj?.goalId,
        title: this.getTitle(type, title, formValue.goalName),
        description: formValue.goalDescription,
        developmentPlanId: formValue.developmentPlan,
        category: formValue.category !== 2 ? this.CATEGORY_ASSIGNED : this.CATEGORY_RECOMMEND,
        type: type,
        typeIdentifier: id || this.constructTypeIdentifierForCustom(formValue.goalName, formValue.measurement, formValue.developmentPlan),
        currentValue: currentRating === this.currentRatingReadonlyValue ? null : currentRating,
        requiredValue: formValue.targetRating?.toString(),
        sendForApproval: this.planForm.controls['managerApproval'].value,
        userGroupIds: this.generateUserGroupId(formValue),
        goalDeadline: formValue.dueDate ? new Date(formValue.dueDate)?.toISOString() : null
      }
    };

    if(type === 'CUSTOM') {
      res.data.currentValue = formValue.measurement?.toLowerCase() === 'complete_incomplete' ? this.planForm.controls?.measurement_currentRating?.value : formValue.currentRating?.toString();
      res.data.requiredValue = formValue.measurement?.toLowerCase() === 'complete_incomplete' ? this.planForm.controls?.measurement_targetRating?.value : formValue.targetRating?.toString();

      res.data.measurement = formValue.measurement?.toUpperCase();

      // TODO: temp changes to support currency different values from FE(BE not ready)
      if(this.currencyOptions.includes(res.data.measurement)) {
        res.data.measurement = 'CURRENCY_' + res.data.measurement;
      }
    }

    if(this.isMyGoals) {
      res.data.userId = this.ds.user.userId
    }

    if(this.isTeamGoals) {
      if(!this.editGoalObj) {
        res.data.userIds = this.userIds?.length ? this.userIds : formValue.goalOwner?.map(x => x.userId);
      } else {
        res.data.userIdForTeamGoal = this.editGoalObj?.userId;
        res.data.sendForApproval = false;
      }
    }

    if (AccomplishmentType.Skill?.toUpperCase() === type) {
      const improvementMethodArray: string[] = formValue.improvementMethod;

      res.data.improvementMethod = improvementMethodArray.map((method: string) => method?.split(' ').join('_').toUpperCase());
    }

    return res;
  }

  private constructTypeIdentifierForCustom(title: string, measurement: string, developmentPlanId: string): string {
    const formattedTitle = title?.toUpperCase()?.replace(/\s+/g, '-');
    const formattedMeasurement = measurement?.toUpperCase();
    return `CUSTOM:${formattedTitle}_${formattedMeasurement}_${developmentPlanId}`;
  }

  private generateUserGroupId(formValue: IFormData): string[] {
    return this.editGoalObj ? this.editGoalObj.userGroups : formValue.userGroups?.map(group => group.id);
  }

  private getAccomplishmentType(selectedAccomplishmentId: string): string {
    return this.accomplishments.find(x => x.id === selectedAccomplishmentId)?.type?.toUpperCase();
  }

  private createNewGoal(requestObj: IDevelopmentPlanRequest) {
    const clientId = this.ds.currentAdminClientId;
    const ENDPOINT = this.isNotAdmin ? API_ENDPOINT.ADD_NEW_GOAL_SMS(clientId) : API_ENDPOINT.ADD_NEW_GOAL_ADMIN(clientId)

    this.ds.careerPrismDataPostApi(ENDPOINT, requestObj).pipe(take(1)).subscribe((res: any) => {
      if(res.ok) {
        const msg = 'Goal added successfully';
        this.toast.showToast({ type: 'success', msg: msg });

        this.createForm();
        if(!this.isNotAdmin) {
          this.resetUserGroupSelection();
        }
        this.refresh.emit();
        this.dismiss();
      }
    }, ((res: HttpErrorResponse) => {
        const msg = res.error?.error?.message;
        const desc = res.error?.error?.error;
        this.toast.showToast({ type: 'error', msg: msg, desc: desc });
        this.resetUserGroupSelection();
    }));
  }

  private updateGoal(requestObj: IDevelopmentPlanRequest) {
    const clientId = this.ds.currentAdminClientId;
    const userId = this.ds.user.userId;

    const ENDPOINT = this.isNotAdmin ? API_ENDPOINT.UPDATE_GOALS_MYGOALS(clientId) : API_ENDPOINT.UPDATE_GLOBAL_GOAL_ADMIN(clientId)

    this.ds.careerPrismDataPostApi(ENDPOINT, requestObj).pipe(take(1)).subscribe((res: any) => {
      if(res.ok) {
        const msg = 'Goal updated successfully';
        this.toast.showToast({ type: 'success', msg: msg });

        this.createForm();
        if(!this.isNotAdmin) {
          this.resetUserGroupSelection();
        }
        this.refresh.emit();
        this.dismiss();
      }
    });
  }

  private resetUserGroupSelection() {
    this.userGroupData.data.userGroupDetails.forEach(group => group.selected = false);
  }

  private getTitle(type: AccomplishmentType, name: string, title: string): string {
    switch (type.toLowerCase()) {
      case AccomplishmentType.Specialization:
        return `Complete ${name} specialization`;
      case AccomplishmentType.Skill:
        return `Develop ${name} skill`;
      case AccomplishmentType.Certification:
        return `Complete ${name} certification`;
      case AccomplishmentType.Role:
        return `Develop into ${name} role`;
      case AccomplishmentType.Course:
        return `Complete ${name} course`;
        default:
          return title;
    }
  }

  // TODO: Move to service after all merged
  private mapMasterGoalDataToCourse(masterGoalData: IMasterCourseData): Partial<ICourse> {
    return {
      _id: masterGoalData.courseId,
      clientId: this.ds.currentAdminClientId,
      description: masterGoalData.courseDescription,
      pk: masterGoalData.courseId,
      skills: masterGoalData.skillItemIds,
      status: masterGoalData.status?.toUpperCase(),
      title: masterGoalData.courseName,
      type: AccomplishmentType.Course?.toUpperCase(),
      enabled: masterGoalData.enabled,
      learningElementPk: masterGoalData.courseId,
    };
  }

  // TODO: Move to service after all merged
  private mapMasterGoalDataToCertification(masterGoalData: ICertificationMasterData): Partial<ICertification> {
    return {
      _id: masterGoalData.certificationId,
      clientId: this.ds.currentAdminClientId,
      createdAt: masterGoalData.createddt,
      description: masterGoalData.data.certificationDescription,
      originCreator: masterGoalData.data.certificationIssuedBy,
      pk: masterGoalData.certificationId,
      status: masterGoalData.data.status?.toUpperCase(),
      title: masterGoalData.data.certificationName,
      type: AccomplishmentType.Certification?.toUpperCase(),
      updatedAt: masterGoalData.modifieddt,
      enabled: masterGoalData.enabled,
      learningElementPk: masterGoalData.certificationId,
    };
  }

  private subCategoryAccomplishmentInit() {
    return  {
      skill: null,
      course: null,
      certification: null,
      specialization: null,
      role: null
    }
  }

  private HandleEditGoalScenario() {
    if (this.editGoalObj) {
       if(this.editGoalObj.type === 'CUSTOM') {
        this.planForm.get('selectedAccomplishment').patchValue(2);
        this.patchEditValue(this.editGoalObj);
      }
      this.fetchSelectedAccomplishmentsList(this.editGoalObj.type.toLowerCase() as AccomplishmentType);

      // Except CUSTOM type this will trigger
      this.dataForSelectedAccomplisment$.pipe(take(1)).subscribe(res => {
        this.getEnrichmentData(this.editGoalObj.typeIdentifier, this.editGoalObj.type.toLowerCase() as AccomplishmentType);
     });
    }
  }

  private buildRequestPayloadAdmin(type: AccomplishmentType): any {
    return {
      search: '',
      forAutoComplete: true,
      userId: this.ds.user.userId,
      type: type,
      clientId: this.ds.currentAdminClientId,
      maxModifiedDt: '',
      limit: 10
    };
  }

  private getPayloadForGoalListBasedOnType(type: AccomplishmentType) {
    const clientId = this.ds.currentAdminClientId;
    const userId = this.ds.user.userId;

    return {
      clientId: clientId,
      type: type,
      id: userId,
    }
  }

  private subscribeToDevelopmentPlanChanges(): void {
    this.planForm.get('developmentPlan')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if(value) {
        this.setMaxDateSelectionForDueDate(value);
        this.planForm.get('dueDate')?.setValue(this.activePlanEndDate);
      }
      const managerApprovalControl = this.planForm.get('managerApproval');
      if (value) {
        managerApprovalControl?.setValue(true);
        managerApprovalControl?.disable();
      } else {
        managerApprovalControl?.enable();
      }
    });
  }

  private setMaxDateSelectionForDueDate(value: any) {
    const planEndDate = this.developmentPlans.filter(x => x.developmentPlanId === value)?.[0]?.planEndDate;
    this.activePlanEndDate = new Date(planEndDate);
  }

  private generatePaginationData(type: any) {
    let paginationData: IPaginationVirtual = {};

    if (type === AccomplishmentType.Skill) {
      paginationData = {
        offset: +(this.selectedAccomplismentApiResponse as ISkillResponseData).next
      };
    } else {
      const paginationStr = (this.selectedAccomplismentApiResponse as ISelectedAccomplishmentResponse).data.paginationStr;
      const { currentEnd, totalResults } = this.util.parsePaginationStr(paginationStr);
      if (currentEnd < totalResults) {
        paginationData = {
          offset: +(this.selectedAccomplismentApiResponse as ISkillResponseData).next || currentEnd
        };
      }
    }
    return paginationData;
  }
}
